/* Generated by Glyphter (http://www.glyphter.com) on  Sun Oct 20 2024*/
@font-face {
    font-family: 'RNG_SCRIV';
    src: url('../fonts/RNG_SCRIV.eot');
    src: url('../fonts/RNG_SCRIV.eot?#iefix') format('embedded-opentype'),
         url('../fonts/RNG_SCRIV.woff') format('woff'),
         url('../fonts/RNG_SCRIV.ttf') format('truetype'),
         url('../fonts/RNG_SCRIV.svg#RNG_SCRIV') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='scrvi-']:before{
	display: inline-block;
   font-family: 'RNG_SCRIV';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.scrvi-section:before{content:'\0041';}
.scrvi-books:before{content:'\0042';}
.scrvi-book:before{content:'\0043';}
.scrvi-book-add:before{content:'\0044';}
.scrvi-book-minus:before{content:'\0045';}
.scrvi-book-bookmark:before{content:'\0046';}
.scrvi-book-heart:before{content:'\0047';}
.scrvi-chapter:before{content:'\0049';}
.scrvi-chapter-add:before{content:'\004a';}
