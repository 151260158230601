/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Scrivendium Icons */
@import "./scriv-font/css/RNG_SCRIV.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

/*Amplif*/
@import '../node_modules/@aws-amplify/ui-angular/theme.css';

// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

html, body {
    height: 100%;
}

body {
    --text-color: #484848;
}

.ql-container {
    min-height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    max-height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    height: calc(100vh - ( 5rem + 2rem + 1rem + 3rem + 5rem + 1rem) );
    //flex: 1;
    //display: flex;
    //flex-direction: column;
    background-color: #ffffff;
    border-bottom-color: rgb(226, 232, 240);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(226, 232, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(226, 232, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    //min-width: 100;
    //width: 100%;
    //flex-grow: 100;
}
.ql-toolbar {
    background-color: #ffffff;
    border-left-color: rgb(226, 232, 240);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(226, 232, 240);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(226, 232, 240);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top-style: solid;
    border-top-width: 1px;
    width: 100%;
    flex-grow: 100;
}
.ql-editor {
    height: 100%;
    background-color: #ffffff;
    //flex: 1;
    overflow-y: auto;
    //width: 100%;
    //flex-grow: 100;
}

mark.comment-off {
    background: white !important;
    border-radius: 0 !important;
    padding: 0 !important;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 13px !important;
    color: #484848 !important;
}

mark.rng-comment {
    display: inline-block;
}

mark.comment-off span.comment-icon {
    display: none;
}

span.comment-icon {
    float: right;
    cursor: pointer;
}
span.comment-icon i,mark {
    padding-left: 0.3em;
}
span.comment-icon mark {
    vertical-align: super;
    font-size: 0.83em;
}

span.comment-icon i {
    font-size: 1.2em;
    vertical-align: baseline;
}

mark.comment-color-0 {
    background: #ffcccc;
    color: #000000;
}

mark.comment-color-1 {
    background: #ccffcc;
    color: #000000;
}

mark.comment-color-2 {
    background: #ccccff;
    color: #0000ff;
}

mark.comment-color-3 {
    background: #ffffcc;
    color: #000000;
}

mark.comment-color-4 {
    background: #ccffff;
    color: #000000;
}

mark.comment-color-5 {
    background: #ffccff;
    color: #000000;
}

mark.comment-color-6 {
    background: #ffe0cc;
    color: #000000;
}

mark.comment-color-7 {
    background: #e0ffe0;
    color: #000000;
}

mark.comment-color-8 {
    background: #e0ccff;
    color: #000000;
}

mark.comment-color-9 {
    background: #ffcce0;
    color: #000000;
}

mark.comment-color-10 {
    background: #e0cce0;
    color: #000000;
}

//Reveal-codes support

.no-reveal-codes {
    display: none;
}

.reveal-codes {
    display: block;
}

//Toolbar splitbutton for Quill

.ql-picker-item-disabled {
    color: lightgray !important;
    cursor: initial !important;
}

.ql-picker-item-disabled:hover {
    color: lightgray !important;
    cursor: initial !important;
}


.ql-picker-disabled:hover {
    color: lightgray !important;
    cursor: initial !important;
    display: none !important;
}

.ql-picker-disabled {
    color: lightgray !important;
    cursor: initial !important;
    display: none !important;
}

.ql-section-icon-span:hover {
    color: #64B5F6;
    cursor: pointer;
}

.ql-section-dd-span {
    inline-size: 4ch;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-inline-start: var(--border);
    border-start-end-radius: var(--radius);
    border-end-end-radius: var(--radius);

    &:is(:hover,:focus-within) {
        background: var(--theme-hover);
    }

    /* fixes iOS trying to be helpful */
    &:focus {
        outline: none;
    }

    &:active {
        background: var(--theme-active);
    }

    &:focus-within {
        & > svg {
            transition-duration: var(--in-speed);
            transform: rotateZ(.5turn);
        }
        & > .gui-popup {
            transition-duration: var(--in-speed);
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }
    }

    @media (--motionOK) {
        & > svg {
            transition: transform var(--out-speed) ease;
        }
        & > .gui-popup {
            transform: translateY(5px);

            transition:
                opacity var(--out-speed) ease,
                transform var(--out-speed) ease;
        }
    }
}

.ql-section-dd-popup {
    --shadow: 220 70% 15%;
    --shadow-strength: 1%;

    opacity: 0;
    pointer-events: none;

    position: absolute;
    bottom: 80%;
    left: -1.5ch;

    list-style-type: none;
    background: var(--popupbg);
    color: var(--theme-text);
    padding-inline: 0;
    padding-block: .5ch;
    border-radius: var(--radius);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: .9em;
    transition: opacity var(--out-speed) ease;

    box-shadow:
        0 -2px 5px 0 hsl(var(--shadow) / calc(var(--shadow-strength) + 5%)),
        0 1px 1px -2px hsl(var(--shadow) / calc(var(--shadow-strength) + 10%)),
        0 2px 2px -2px hsl(var(--shadow) / calc(var(--shadow-strength) + 12%)),
        0 5px 5px -2px hsl(var(--shadow) / calc(var(--shadow-strength) + 13%)),
        0 9px 9px -2px hsl(var(--shadow) / calc(var(--shadow-strength) + 14%)),
        0 16px 16px -2px hsl(var(--shadow) / calc(var(--shadow-strength) + 20%));

    & button {
        color: var(--theme-text);
        width: 100%;
    }


}


